import React from "react"

import {
  SEO,
  Layout,
  Landing,
  Services,
  Implementations,
  AboutUs,
  Contact,
} from "../components"

const index = () => {
  return (
    <Layout>
      <SEO title="Naprawa i renowacja łodzi, jachtów, wyrobów z laminatu"></SEO>
      <Landing></Landing>
      <Services></Services>
      <Implementations></Implementations>
      <AboutUs></AboutUs>
      <Contact></Contact>
    </Layout>
  )
}

export default index
